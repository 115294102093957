<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab
        v-for="(language, index) in languages"
        :key="language.id"
        :href="`#${index}`"
        v-on="$listeners"
      >
        <slot
          name="title"
          :index="index"
          :language="language"
          :has-errors="!!errors[itemIndex(language.id)]"
        >
          <span
            :class="{
              'red--text': !!errors[itemIndex(language.id)],
            }"
          >
            {{ language.name }}
          </span>
        </slot>
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(language, index) in languages"
          :key="language.id"
          :eager="eager"
          :value="`${index}`"
        >
          <slot
            :index="index"
            :language="language"
            :translation="translationsLocal[index]"
            :errors="errors[itemIndex(language.id)]"
            :on="getEventsItem(index)"
          >
            {{ language.name }}
          </slot>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { find, findIndex } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LanguageTabs',

  provide() {
    return {
      register: this.addForm,
      unregister: this.removeForm,
    };
  },

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    translations: {
      type: Array,
      required: false,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    eager: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      tab: null,
      forms: [],
      languages: [],
    };
  },

  computed: {
    ...mapGetters('localization', ['lang']),
    translationsLocal() {
      const translations = [];

      this.languages.forEach((language, index) => {
        translations[index] = find(this.realValue, {
          language_id: language.id,
        });
      });

      return translations;
    },

    realValue() {
      return this.value ? this.value : this.translations;
    },
  },

  mounted() {
    this.retrieveLanguages()
      .then((response) => {
        this.languages = response.data;
        return this.languages;
      })
      .then((value) => {
        let val = this.value
          .sort((a, b) => a.language_id.toString().localeCompare(b.language_id))
          .find((el) => el.language_id);
        if (val != null) {
          this.tab = `${this.languages.indexOf(
            value.find((el) => el.id === val.language_id)
          )}`;
        }
      });
  },

  methods: {
    ...mapActions('localization', ['retrieveLanguages']),
    getEventsItem(index) {
      return {
        input: (value) => this.updateValue(index, value),
      };
    },
    updateValue(index, value) {
      const data = this.translationsLocal;
      data[index] = value;
      this.$emit(
        'input',
        data.filter(function (el) {
          return el != null;
        })
      );
    },
    addForm(component) {
      this.forms.push(component);
    },
    removeForm(component) {
      this.forms = this.forms.filter((i) => i._uid !== component._uid);
    },

    itemIndex(index) {
      return findIndex(this.value, (el) =>
        el != null ? el.language_id === index : false
      );
    },
  },
};
</script>
